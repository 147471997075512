import React, { useEffect, useState, useMemo } from "react";
import IconButton from "../../utils/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useLocalPersistState from "../../../hooks/useLocalPersistState";
import Stats from "./Stats";
import { Dialog } from "@mui/material";
import {
	DEFAULT_COUNT_DOWN,
	ENGLISH_MODE,
	PACING_CARET,
	PACING_PULSE,
} from "../../../constants/Constants";
// import DefaultKeyboard from "../Keyboard/DefaultKeyboard";
import axios from "axios";

const TypeBox = ({ textInputRef, isFocusedMode, handleInputFocus }) => {
	const [isTestRunning, setIsTestRunning] = useState(false);

	const [paragraph, setParagraph] = useState([]);
	const [words, setWords] = useState([]);
	const [backSpace, setBackSpace] = useState(false);
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const testId = urlParams.get("test_id");
		axios
			.get(`https://guardianeb.com/api/typing-practice/${testId}`)
			.then((response) => {
				const data = response.data;
				if (data.data.paragraph && data.data.time_limit) {
					setWords(data.data.paragraph.split(" "));
					setParagraph(data.data.paragraph.split(" "));
					setCountDownConstant(data.data.time_limit * 60);

					if (data.data.backspace) {
						setBackSpace(true);
					}
				} else {
					console.error("Paragraph or time_limit not found in API response");
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const totalWords = words.length;
	const startTest = () => {
		// Reset countdown state if test is finished
		if (status === "finished") {
			reset(countDownConstant, language, false);
		}

		if (!isTestRunning || status === "finished") {
			setIsTestRunning(true);
			start();
		} else {
			submitTest();
		}
	};

	const submitTest = () => {
		setIsTestRunning(false);
		clearInterval(intervalId);
		if (status !== "finished") {
			setStatus("finished");
			// Calculate the stats
			const currCharExtraCount = Object.values(history)
				.filter((e) => typeof e === "number")
				.reduce((a, b) => a + b, 0);

			// Use the size property of the wordsCorrect Set to get the count of correct words
			const currCharCorrectCount = wordsCorrect.size;

			const currCharIncorrectCount = wordsInCorrect.size;

			const currCharAdvancedCount = totalWords;
			const currCharMissingCount = Math.abs(
				currCharCorrectCount + currCharIncorrectCount - currCharAdvancedCount
			);

			const accuracy =
				currCharCorrectCount === 0
					? 0
					: (currCharCorrectCount /
							(currCharCorrectCount + currCharIncorrectCount)) *
					  100;

			setStatsCharCount([
				accuracy,
				currCharCorrectCount,
				currCharIncorrectCount,
				currCharMissingCount,
				currCharAdvancedCount,
				currCharExtraCount,
			]);
		}
	};

	// local persist timer
	const [countDownConstant, setCountDownConstant] = useLocalPersistState(
		DEFAULT_COUNT_DOWN,
		"timer-constant"
	);

	// local persist pacing style
	const [pacingStyle] = useLocalPersistState(
		PACING_PULSE,
		"pacing-style"
	);

	// local persist difficulty
	const [language, setLanguage] = useLocalPersistState(
		ENGLISH_MODE,
		"language"
	);

	// Caps Lock
	//const [capsLocked, setCapsLocked] = useState(false);

	// tab-enter restart dialog
	const [openRestart, setOpenRestart] = useState(false);

	const EnterkeyPressReset = (e) => {
		// press enter/or tab to reset;
		if (e.keyCode === 13 || e.keyCode === 9) {
			e.preventDefault();
			setOpenRestart(false);
			reset(countDownConstant, language, false);
		} // press space to redo
		else if (e.keyCode === 32) {
			e.preventDefault();
			setOpenRestart(false);
			reset(countDownConstant, language, true);
		} else {
			e.preventDefault();
			setOpenRestart(false);
		}
	};
	const handleTabKeyOpen = () => {
		setOpenRestart(true);
	};

	const wordSpanRefs = useMemo(
		() =>
			Array(words.length)
				.fill(0)
				.map((i) => React.createRef()),
		[words]
	);

	// set up timer state
	const [countDown, setCountDown] = useState(countDownConstant);
	const [intervalId, setIntervalId] = useState(null);

	// set up game loop status state
	const [status, setStatus] = useState("waiting");

	// set up hidden input input val state
	const [currInput, setCurrInput] = useState("");
	// set up world advancing index
	const [currWordIndex, setCurrWordIndex] = useState(0);
	// set up char advancing index
	const [currCharIndex, setCurrCharIndex] = useState(-1);
	const [prevInput, setPrevInput] = useState("");

	// set up words examine history
	const [wordsCorrect, setWordsCorrect] = useState(new Set());
	const [wordsInCorrect, setWordsInCorrect] = useState(new Set());
	const [inputWordsHistory, setInputWordsHistory] = useState({});

	// setup stats
	const [rawKeyStrokes, setRawKeyStrokes] = useState(0);
	const [wpmKeyStrokes, setWpmKeyStrokes] = useState(0);
	const [wpm, setWpm] = useState(0);
	const [statsCharCount, setStatsCharCount] = useState([]);

	// set up char examine hisotry
	const [history, setHistory] = useState({});
	const keyString = currWordIndex + "." + currCharIndex;
	const [currChar, setCurrChar] = useState("");

	useEffect(() => {
		if (
			currWordIndex !== 0 &&
			wordSpanRefs[currWordIndex].current.offsetLeft <
				wordSpanRefs[currWordIndex - 1].current.offsetLeft
		) {
			wordSpanRefs[currWordIndex - 1].current.scrollIntoView();
		} else {
			return;
		}
	}, [currWordIndex, wordSpanRefs, language]);
	useEffect(() => {
		// Calculate WPM only when the timer is running
		if (status === "started") {
			// Calculate WPM
			const currWordCorrectCount = wordsCorrect.size;

			// Number of incorrect words
			const totalTimeInSeconds = countDownConstant - countDown; // Example: total time in seconds

			//   const totalTimeInMinutes = totalTimeInSeconds * 60; // Convert seconds to minutes

			// Calculate WPM
			const currentWpm = (currWordCorrectCount / totalTimeInSeconds) * 60; // Example: Number of correct words typed by the user

			setWpm(currentWpm);
		}
	}, [status, wpmKeyStrokes, countDown, countDownConstant, wordsCorrect.size]);
	const reset = (newCountDown, language, isRedo) => {
		setStatus("waiting");

		setCountDownConstant(newCountDown);
		setCountDown(newCountDown);

		setLanguage(language);
		clearInterval(intervalId);
		setWpm(0);
		setRawKeyStrokes(0);
		setWpmKeyStrokes(0);
		setCurrInput("");
		setPrevInput("");
		setIntervalId(null);
		setCurrWordIndex(0);
		setCurrCharIndex(-1);
		setCurrChar("");
		setHistory({});
		setInputWordsHistory({});
		setWordsCorrect(new Set());
		setWordsInCorrect(new Set());
		textInputRef.current.focus();

		wordSpanRefs[0].current.scrollIntoView();
	};

	const start = () => {
		if (status === "finished") {
			setCurrInput("");
			setPrevInput("");
			setCurrWordIndex(0);
			setCurrCharIndex(-1);
			setCurrChar("");
			setHistory({});
			setInputWordsHistory({});
			setWordsCorrect(new Set());
			setWordsInCorrect(new Set());

			setStatus("waiting");
			textInputRef.current.focus();
		}

		if (status !== "started") {
			setStatus("started");
			let intervalId = setInterval(() => {
				setCountDown((prevCountdown) => {
					if (prevCountdown === 0) {
						clearInterval(intervalId);
						// current total extra inputs char count
						const currCharExtraCount = Object.values(history)
							.filter((e) => typeof e === "number")
							.reduce((a, b) => a + b, 0);

						// current correct inputs char count
						const currCharCorrectCount = wordsCorrect.size;

						const currCharIncorrectCount = wordsInCorrect.size;

						// current missing inputs char count

						// current total advanced char counts
						const currCharAdvancedCount = totalWords;
						const currCharMissingCount = Math.abs(
							currCharCorrectCount +
								currCharIncorrectCount -
								currCharAdvancedCount
						);

						const accuracy =
							currCharCorrectCount === 0
								? 0
								: (currCharCorrectCount /
										(currCharCorrectCount + currCharIncorrectCount)) *
								  100;

						setStatsCharCount([
							accuracy,
							currCharCorrectCount,
							currCharIncorrectCount,
							currCharMissingCount,
							currCharAdvancedCount,
							currCharExtraCount,
						]);

						checkPrev();
						setStatus("finished");

						return countDownConstant;
					} else {
						return prevCountdown - 1;
					}
				});
			}, 1000);
			setIntervalId(intervalId);
		}
	};

	const UpdateInput = (e) => {
		if (status === "finished") {
			return;
		}
		// console.log(e.target.value);
		setCurrInput(e.target.value);
		inputWordsHistory[currWordIndex] = e.target.value.trim();
		setInputWordsHistory(inputWordsHistory);
	};

	const handleKeyUp = (e) => {
    //setCapsLocked(e.getModifierState("CapsLock"));
    //console.log([e.target.value.length]);
		if (
			words[currWordIndex].toUpperCase() === "I" &&
      e.target.value.trim().toUpperCase() === "I" && 
      e.target.value.length > 1
		) {
			handleSpace();
		}
		// console.log(words[currWordIndex]);
		// console.log(e.target.value);
	};

  const handleKeyDown = (e) => {
    
		const key = e.key;
		const keyCode = e.keyCode;

		// Prevent the default action of specific keys
		if (
			keyCode === 13 ||
			keyCode === 20 ||
			(keyCode >= 16 && keyCode <= 18) ||
			keyCode === 9
		) {
			e.preventDefault();
			if (keyCode === 9) {
				handleTabKeyOpen();
			}
			return;
		}

		// If the game is finished, clear inputs and return
		if (status === "finished") {
			setCurrInput("");
			setPrevInput("");
			return;
		}

		// Ignore key if no valid key or language-specific processing needed
		if (!key || keyCode === 229) {
			return false;
		}

		// Start the game by typing any key if not already started
		if (status !== "started") {
			start();
    }
    
		// Handle backspace
		if (keyCode === 8) {
			// Check if backSpace state is false, then return without doing anything
			if (!backSpace) {
				e.preventDefault();
				return;
			}

			// Delete the mapping match records
			delete history[keyString];

			// Avoid over delete
			if (currCharIndex < 0) {
				// Only allow delete previous word, rewind to previous
				if (wordsInCorrect.has(currWordIndex - 1)) {
					const prevInputWord = inputWordsHistory[currWordIndex - 1];
					setCurrInput(prevInputWord + " ");
					setCurrCharIndex(prevInputWord.length - 1);
					setCurrWordIndex(currWordIndex - 1);
					setPrevInput(prevInputWord);
				}
				return;
			}

			// Regular backspace behavior
			setCurrCharIndex(currCharIndex - 1);
			setCurrChar("");
			return;
		}

		// For other keys, increment character index and update current character
		setCurrCharIndex(currCharIndex + 1);
		setCurrChar(key);
	};

	function handleSpace() {
		const prevCorrectness = checkPrev();
		// advance to next regardless prev correct/not
		if (prevCorrectness === true || prevCorrectness === false) {
			// reset currInput
			setCurrInput("");
			// advance to next
			setCurrWordIndex(currWordIndex + 1);
			setCurrCharIndex(-1);
		}
	}

	function onChangeSpaceHandle(e) {
		var key = e.target.value.slice(-1);
		//console.log(key);
		if (key === " ") {
			handleSpace();
		} else {
			setCurrChar(key);
		}
	}

	const getExtraCharClassName = (i, idx, extra) => {
		if (
			pacingStyle === PACING_CARET &&
			currWordIndex === i &&
			idx === extra.length - 1
		) {
			return "caret-extra-char-right-error";
		}

		return "error-char";
	};

	const getExtraCharsDisplay = (word, i) => {
		let input = inputWordsHistory[i];
		if (!input) {
			input = currInput.trim();
		}
		if (i > currWordIndex) {
			return null;
		}
		if (input.length <= word.length) {
			return null;
		} else {
			const extra = input.slice(word.length, input.length).split("");
			history[i] = extra.length;
			return extra.map((c, idx) => (
				<span key={idx} className={getExtraCharClassName(i, idx, extra)}>
					{c}
				</span>
			));
		}
	};

	const checkPrev = () => {
		const wordToCompare = words[currWordIndex];
		const currInputWithoutSpaces = currInput.trim();

		if (!currInputWithoutSpaces || currInputWithoutSpaces.length === 0) {
			return null;
		}

		const isCorrect = wordToCompare === currInputWithoutSpaces;

		let inputWordsHistoryUpdate = { ...inputWordsHistory };
		inputWordsHistoryUpdate[currWordIndex] = currInputWithoutSpaces;
		setInputWordsHistory(inputWordsHistoryUpdate);

		if (isCorrect) {
			// Mark word as correct, but only if it was never incorrect
			if (!wordsInCorrect.has(currWordIndex)) {
				wordsCorrect.add(currWordIndex);
				setWpmKeyStrokes(wpmKeyStrokes + wordToCompare.length + 1);
			}
			setPrevInput("");
			return true;
		} else {
			// Mark word as incorrect and ensure it stays incorrect
			wordsInCorrect.add(currWordIndex);
			wordsCorrect.delete(currWordIndex);
			setPrevInput(prevInput + " " + currInputWithoutSpaces);
			return false;
		}
	};

	const getWordClassName = (wordIdx) => {
		if (wordsInCorrect.has(wordIdx)) {
			if (currWordIndex === wordIdx) {
				if (pacingStyle === PACING_PULSE) {
					return "word error-word active-word";
				} else {
					return "word error-word active-word-no-pulse";
				}
			}

			//console.log(currWordIndex, wordIdx, wordsInCorrect);
			return "word error-word";
		} else {
			if (currWordIndex === wordIdx) {
				if (pacingStyle === PACING_PULSE) {
					return "word active-word";
				} else {
					return "word active-word-no-pulse";
				}
			}
			return "word";
		}
	};

	const getCharClassName = (wordIdx, charIdx, char, word) => {
		// Get the screen width
		const screenWidth = window.innerWidth;

		if (screenWidth < 431) {
			const keyString = wordIdx + "." + charIdx;

			// Check if this is the current word
			const isCurrentWord = wordIdx === currWordIndex;
			const isCompletedWord = wordIdx < currWordIndex;

			// Handle the caret position
			if (
				pacingStyle === PACING_CARET &&
				isCurrentWord &&
				charIdx === currCharIndex + 1 &&
				status !== "finished"
			) {
				return "caret-char-left";
			}

			// For completed words, use stored history
			if (isCompletedWord) {
				return history[keyString] ? "correct-char" : "error-char";
			}

			// For the current word, compare the input character-by-character
			if (isCurrentWord) {
				if (charIdx < currInput.length) {
					const inputChar = currInput[charIdx];
					if (inputChar === char) {
						history[keyString] = true;
						return "correct-char";
					} else {
						history[keyString] = false;
						return "error-char";
					}
				} else if (charIdx === currInput.length) {
					// If this is the next character to type, leave it neutral
					return "caret-char-right";
				}
			}

			// For future characters or untouched words, keep neutral styling
			return "char";
		} else {
			// Apply the second version of getCharClassName for larger screens
			const keyString = wordIdx + "." + charIdx;

			if (
				pacingStyle === PACING_CARET &&
				wordIdx === currWordIndex &&
				charIdx === currCharIndex + 1 &&
				status !== "finished"
			) {
				return "caret-char-left";
			}
			if (history[keyString] === true) {
				if (
					pacingStyle === PACING_CARET &&
					wordIdx === currWordIndex &&
					word.length - 1 === currCharIndex &&
					charIdx === currCharIndex &&
					status !== "finished"
				) {
					return "caret-char-right-correct";
				}
				return "correct-char";
			}
			if (history[keyString] === false) {
				if (
					pacingStyle === PACING_CARET &&
					wordIdx === currWordIndex &&
					word.length - 1 === currCharIndex &&
					charIdx === currCharIndex &&
					status !== "finished"
				) {
					return "caret-char-right-error";
				}
				return "error-char";
			}
			if (
				wordIdx === currWordIndex &&
				charIdx === currCharIndex &&
				currChar &&
				status !== "finished"
			) {
				if (char === currChar) {
					history[keyString] = true;
					return "correct-char";
				} else {
					history[keyString] = false;
					return "error-char";
				}
			} else {
				if (wordIdx < currWordIndex) {
					// missing chars
					history[keyString] = undefined;
				}

				return "char";
			}
		}
	};

	const handleEnterKeyPress = (e) => {
		if (e.keyCode === 13) {
			// Check if Enter key was pressed
			e.preventDefault(); // Prevent the default action
		}
	};
	return (
		<div className="container area">
			{(status === "finished" || language === ENGLISH_MODE) && (
				<div>
					{language === ENGLISH_MODE && (
						<div className="type-box">
							<div className="words">
								{paragraph.map((word, i) => (
									<span
										key={i}
										ref={wordSpanRefs[i]}
										className={getWordClassName(i)}
									>
										{word.split("").map((char, idx) => (
											<span
												key={"word" + idx}
												className={getCharClassName(i, idx, char, word)}
											>
												{char}
											</span>
										))}
										{getExtraCharsDisplay(word, i)}
									</span>
								))}
							</div>
						</div>
					)}
					{status === "finished" && (
						<div className="stats">
							<Stats
								status={status}
								git
								wpm={wpm}
								countDown={countDown}
								countDownConstant={countDownConstant}
								statsCharCount={statsCharCount}
								rawKeyStrokes={rawKeyStrokes}
							/>
						</div>
					)}
				</div>
			)}

			{status !== "finished" && (
				<div onClick={handleInputFocus}>
					{/* <CapsLockSnackbar open={capsLocked} /> */}
					<div className="stats stats-time"></div>
					<textarea
						key="hidden-input"
						ref={textInputRef}
						type="text"
						className="my-xl-0 my-lg-0 my-md-2 my-4 textarea border border-dark border-1  h-100 container "
						onKeyDown={(e) => {
							handleKeyDown(e);
							handleEnterKeyPress(e);
						}}
						onKeyUp={(e) => handleKeyUp(e)}
						value={currInput}
						onChange={(e) => {
							setCurrInput(e.target.value);
							UpdateInput(e);
							onChangeSpaceHandle(e);
						}}
						disabled={!isTestRunning} // Disable the textarea when the test is not running
					/>
				</div>
			)}

			<h5 className="time-data text-dark">Time: {countDown} s</h5>
			{status === "finished" && (
				<div className="d-flex align-items-center justify-content-center mb-2 gap-2">
					<a
						href="https://guardianeb.com/user/dashboard"
						rel="noopener noreferrer"
					>
						<img
							src="https://guardianeb.com/public/assets/frontend/img/favicon.jpg"
							alt="Guardian"
							width={"50px"}
						/>
					</a>
					<a
						href="https://guardianeb.com/user/dashboard"
						rel="noopener noreferrer"
					>
						<b style={{ color: "#0c6fb2" }}>HOME</b>
					</a>
				</div>
			)}

			<div className="restart-button" key="restart-button">
				<Grid container justifyContent="center" alignItems="center">
					<Box display="flex" flexDirection="row">
						<IconButton
							aria-label={isTestRunning ? "submit" : "restart"}
							className="text-dark"
							size="medium"
							onClick={startTest}
						>
							{isTestRunning ? (
								<button className="btn text-white stats-btn px-2 py-1 no-hover-effect">
									Submit
								</button>
							) : (
								<button className="btn text-white stats-btn px-2 py-1 no-hover-effect">
									Start
								</button>
							)}
						</IconButton>
					</Box>
				</Grid>
			</div>

			<Dialog
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
					},
				}}
				open={openRestart}
				onKeyDown={EnterkeyPressReset}
			></Dialog>
		</div>
	);
};

export default TypeBox;
