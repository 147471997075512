import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { defaultTheme, themesOptions } from "./style/theme";
import { GlobalStyles } from "./style/global";
import TypeBox from "./components/features/TypeBox/TypeBox";
import "./App.css";
import textCorrection1 from "./assets/images/text-correction-1.jpeg";
import textCorrection2 from "./assets/images/text-correction-2.jpeg";
import physicalKeyboard1 from "./assets/images/physical-keyboard-1.jpeg";
import physicalKeyboard2 from "./assets/images/physical-keyboard-2.jpeg";

function App() {
	const [showModal, setShowModal] = useState(true);

	// localStorage persist theme setting
	const [theme] = useState(() => {
		const stickyTheme = window.localStorage.getItem("theme");
		if (stickyTheme !== null) {
			const localTheme = JSON.parse(stickyTheme);
			const upstreamTheme = themesOptions.find(
				(e) => e.label === localTheme.label
			).value;
			// we will do a deep equal here. In case we want to support customized local theme.
			const isDeepEqual = localTheme === upstreamTheme;
			return isDeepEqual ? localTheme : upstreamTheme;
		}
		return defaultTheme;
	});

	return (
		<ThemeProvider theme={theme}>
			<>
				<div className="canvas">
					<GlobalStyles />
					<div className="typing-heading ">
						<h3 className="fs-5">Typing Practice</h3>
					</div>

					{showModal && (
						<div className="modal">
							<div className="modal-body">
								<div className="d-flex justify-content-between">
									<h5 className="fw-bold">Important Note</h5>
									<button
										type="button"
										className="btn-close"
										onClick={() => setShowModal(false)}
									></button>
								</div>
								<hr />
								<p>
									If you are using on screen keyboard (e.g. on iOS, android or
									any other device), please turn off following "Text correction"
									features to make this work properly.
								</p>
								<ul className="ms-3 mb-2">
									<li>Auto-correction</li>
									<li>Autospace after punctuation</li>
									<li>Auto-capitalization</li>
									<li>Double space after punctuation / period</li>
								</ul>
								<div className="row g-2">
									<div className="col-6">
										<img
											src={textCorrection1}
											alt="textCorrection1"
											className="w-100"
										/>
									</div>
									<div className="col-6">
										<img
											src={textCorrection2}
											alt="textCorrection2"
											className="w-100"
										/>
									</div>
								</div>
								<hr />
								<p>
									If you are using physical keyboard with mobile phones or with
									tablets, make sure to turn off "Text correction" features on
									physical keyboard also.
								</p>
								<div className="row g-2">
									<div className="col-6">
										<img
											src={physicalKeyboard1}
											alt="textCorrection1"
											className="w-100"
										/>
									</div>
									<div className="col-6">
										<img
											src={physicalKeyboard2}
											alt="textCorrection2"
											className="w-100"
										/>
									</div>
								</div>
								<hr />
								<div className="text-end">
									<button
										type="button"
										className="btn btn-primary px-4"
										onClick={() => setShowModal(false)}
									>
										<span className="btn-close me-2 text-white fs-5"></span>
										Close
									</button>
								</div>
							</div>
						</div>
					)}

					<TypeBox key="type-box"></TypeBox>
				</div>
			</>
		</ThemeProvider>
	);
}

export default App;
